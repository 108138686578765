import Layout from "@/layout";

const approvalRouter = {
  path: '/approve',
  name: 'approve',
  component: Layout,
  meta: { title: '流程中心' },
  children: [
    {
      path: 'list/:applyClassify',
      component: () => import('@/views/approve/list'),
      name: 'ApproveList',
      meta: {title: '审批记录', keepAlive: false}
    }
  ]
}

export default approvalRouter;