import request from "@/utils/request"
import qs from "qs"

const api = {
  account: "/admin/xt.manage/getAccount",
  bpOpts: '/admin/xt.manage/getBpOpts',
  saveAccount: '/admin/xt.manage/saveAccount',
  toolDownloadUrl: '/api/talent/getFetchUrls',
  filterOpts: '/admin/xt.manage/opts',
  delAccount: '/admin/xt.manage/delAccount',
  tagOpts: '/admin/dict/index',
  accountOpts: '/admin/xt.manage/getAccountOpts',
  addAccont: '/admin/xt.manage/addAccount',
}

// 获取账号信息
export function getAccount(id)
{
  return request({
    url: `${api.account}`,
    method: "get",
    params: {id}
  })
}

// 获取BP下拉数据
export function getBpOpts(type)
{
  return request({
    url: api.bpOpts,
    method: 'get',
    params:  {type},
  });
}

/**
 * 保存账号信息
 * @param {*} params 
 * @returns 
 */
export function saveAccount(params)
{
  return request({
    url: api.saveAccount,
    method: 'post',
    data: qs.stringify(params)
  });
}

/**
 * 获取采集工具的下载地址
 * @param {*} params 
 * @returns 
 */
export function getGrabToolDownloadUrl(params)
{
  return request({
    url: api.toolDownloadUrl,
    method: 'post',
    data: qs.stringify(params)
  })
}

/**
 * 获取筛选框的下拉数据
 * @returns 
 */
export function getFilterOpts(type)
{
  return request({
    url: api.filterOpts,
    method: 'get',
    params:  {type},
  });
}

/**
 * 删除账号信息
 * @param Number id
 */
export function delAccount(id)
{
  return request({
    url: api.delAccount,
    method: 'post',
    data: qs.stringify({id})
  });
}

/**
 * 获取标签下拉数据
 * @returns 
 */
export function getTagOpts()
{
  return request({
    url: api.tagOpts,
    method: 'get',
  });
}

/**
 * 获取长沙账号中归属于抖音平台的数据
 * @returns 
 */
export function getAccountOpts(type)
{
  return request({
    url: api.accountOpts,
    method: 'get',
    params: {type},
  });
}

/**
 * 添加达人账号
 * @returns 
 */
export function addAccount(params) {
  return request({
    url: api.addAccont,
    method: 'post',
    data: qs.stringify(params)
  });
}