const state = {
  show_plan_detail: false,
  show_plan_form: false,
  show_plan_list: false,
  show_plan_list_wechat: false,
  show_plan_list_video_wechat: false,
  show_join_plan_form: false,
  plan_form_select_disable: false,
  plan_package_name: "",
  plan_packages: [],
}
const mutations = {
  changeShowPlanDetail(state, isShow) {
    state.show_plan_detail = isShow
  },
  changeShowPlanForm(state, isShow) {
    state.show_plan_form = isShow
  },
  changeShowPlanList(state, isShow) {
    state.show_plan_list = isShow
  },
  changeShowPlanListWechat(state, isShow) {
    state.show_plan_list_wechat = isShow
  },
  changeShowPlanListVideoWechat(state, isShow) {
    state.show_plan_list_video_wechat = isShow
  },
  changePlanFormSelectDisable(state, isDisabled) {
    state.plan_form_select_disable = isDisabled
  },
  changeShowJoinPlanForm(state, isShow) {
    state.show_join_plan_form = isShow
  },
  changePlanPackageName(state, name) {
    state.plan_package_name = name
  },
  changePlanPackages(state, value) {
    state.plan_packages = value
  },
  addPlanPackage(state, value) {
    state.plan_packages.push(value)
  },
}
const actions = {}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
