import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/layout'
import approvalRouter from './modules/approval'

const originalPush = Router.prototype.push

Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

export const constantRoutes = [
  {
    path: '/',
    component: Layout,
  },
  {
    path: '/admin/wwlogin',
    component: () => import('@/views/login/index')
  },
  {
    path: '/admin/login',
    component: () => import('@/views/login/wwindex')
  },
  {
    path: '/admin/qywxlogin',
    component: () => import('@/views/login/qywxindex')
  },
  {
    path: '/admin/fslogin',
    component: () => import('@/views/login/fsindex')
  },
  {
    path: '/admin/fsmdlogin',
    component: () => import('@/views/login/fsmdlogin')
  },
  {
    path: '/tv/overview',
    name: 'TvOverview',
    component: () => import('@/views/tv/overview')
  },
  {
    path: '/admin/qywxauth',
    component: () => import('@/views/login/qywxauth')
  },
  {
    path: '/approve/design',
    name: 'design',
    component: () => import('@/views/approve/design')
  },
  approvalRouter,
]

const createRouter = () => new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
