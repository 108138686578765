import Vue from 'vue'
import App from './App.vue'
import router from './router'

import '@/styles/component.scss'
import 'normalize.css/normalize.css' // A modern alternative to CSS resets

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import '@/styles/index.scss' // global css

import '@/icons' // icon
import '@/assets/iconfont/iconfont.css'
import '@/permission' // permission control

// approve
import "@/assets/theme.less";
import Ellipsis from '@/components/common/Ellipsis'
import WDialog from '@/components/common/WDialog'
import Tip from '@/components/common/Tip'

import store from '@/store'

import '@/rem' // rem

require('@/utils/CustomUtil')
Vue.use(ElementUI)
Vue.use(Ellipsis);
Vue.use(WDialog);
Vue.use(Tip);

Vue.config.productionTip = false

Vue.prototype.$isNotEmpty = function(obj){
  return (obj !== undefined && obj !== null && obj !== '' && obj !== 'null')
}

Vue.prototype.$getDefalut = function(obj, key, df){
  return (obj === undefined || key === undefined || !this.$isNotEmpty(obj[key])) ? df : obj[key];
}

Vue.prototype.$deepCopy = function (obj){return JSON.parse(JSON.stringify(obj))}

new Vue({
  router,
  store,
  data() {
    return {
      totalScore: 0,
      needRefreshTargetData: 0, // 是否需要刷新目标相关列表，关系图等，通过监听方式
      needRefreshWorktable: 0, // 是否需要刷新工作台
      isUseZoom: false, // 是否使用了zoom
	    needRefreshMessage:0,
      jumpToClipMenu: false, // 是否跳转到后期管理菜单
    }
  },
  render: h => h(App),
}).$mount('#app')
