import request from '@/utils/request'
import qs from 'qs'

export function login(data) {
  // console.log(data)
  return request({
    url: '/admin/index/login',
    method: 'post',
    data: qs.stringify(data)
  })
}

export function getInfo() {
  return request({
    url: '/admin/member/getInfo',
    method: 'post',
    // params: qs.stringify({ token })
  })
}

export function logout() {
  return request({
    url: '/admin/admin/logout',
    method: 'post',
  })
}

export function wwlogin(data) {
  return request({
    url: '/admin/index/wwlogin',
    method: 'post',
    data: qs.stringify(data)
  })
}

export function fslogin(data) {
  return request({
    url: '/admin/index/fslogin',
    method: 'post',
    data: qs.stringify(data)
  })
}

export function qywxlogin(params)
{
  return request({
    url: '/index/login/qywxlogin',
    method: 'get',
    params
  })
}

export function fsloginMobile(data) {
  return request({
    url: '/admin/index/fsloginMobile',
    method: 'post',
    data: qs.stringify(data)
  })
}
