import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import user from './module/user'
import app from './module/app'
import settings from './module/settings'
import tagsView from './module/tagsView'
import plan from './module/plan'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    user,
    app,
    settings,
    tagsView,
    plan
  },
  getters,
  state: {
    nodeMap: new Map(),
    isEdit: null,
    selectedNode: {},
    selectFormItem: null,
    design:{},
    cond: {},
    approvalForm:{}
  },
  mutations: {
    selectedNode(state, val) {
      state.selectedNode = val
    },
    loadForm(state, val){
      state.design = val
    },
    setIsEdit(state, val){
      state.isEdit = val
    },
    setTemplate(state, val){

    },
    setCond(state, val) {
      state.cond = val
    },
    setApprovalForm(state, val) {
      state.approvalForm = val
    }
  },
})

export default store
